
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
  adress: string;
  photo: string;
  classRoomName: "";
  medicalNotes: string;
  phone?: string;
}

export default defineComponent({
  name: "profile-overview",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const studentID = route.params.id;

    const student = ref<IStudent>({
      _id: "",
      BirthDate: "",
      firstName: "",
      lastName: "",
      gender: "",
      adress: "",
      photo: "",
      classRoomName: "",
      medicalNotes: "",
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(t("student.studentProfile"), []);
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match["_id"] = studentID;

      await ApiService.post("students/filter", {
        query: { _id: studentID },
        aggregation: [
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
              medicalNotes: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          console.log(data);
          if (data.length > 0) {
            student.value = data[0];
          } else console.error(data);
        })
        .catch((e) => console.log(e));
    });

    return {
      t,
      student,
      moment,
    };
  },
});
